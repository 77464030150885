<template>
  <div>
  <div v-if="loader" class="d-flex justify-content-center mb-3">
    <b-spinner label="Loading..."></b-spinner>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="3">
        <b-card>
          <b-card-title> Filtrer </b-card-title>
          <b-card-body>
            <h6>Période</h6>

            <b-form-datepicker
              id="from"
              reset-button
              v-model="filter.from"
              class="mb-1"
              placeholder="Début"
              @input="storefilter"
            />

            <b-form-datepicker
              id="to"
              reset-button
              v-model="filter.to"
              class="mb-1"
              placeholder="Fin"
              @input="storefilter"
            />

            <h6>Contact</h6>
            <b-form-group id="wrapper_autocomplete">
              <vue-autosuggest
                ref="autocomplete"
                v-model="filter.query"
                :suggestions="suggestions"
                :input-props="inputProps"
                :section-configs="sectionConfigs"
                :render-suggestion="renderSuggestion"
                :get-suggestion-value="getSuggestionValue"
                @input="fetchResults"
              >
              </vue-autosuggest>
              <span
                class="float-right block"
                id="clear_autosuggest"
                v-on:click="reset_autosuggest"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path
                    d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                  />
                </svg>
              </span>
            </b-form-group>
            <h6>Equipe</h6>
            <b-form-group>
              <v-select
                @input="storefilter"
                v-model="filter.selected_team"
                label="name"
                :options="teams.list"
              />
            </b-form-group>
            <h6>Utilisateur</h6>
            <b-form-group>
              <v-select
                @input="storefilter"
                v-model="filter.selected_user"
                label="email"
                :options="users.list"
              />
            </b-form-group>
            <h6>Tag</h6>
            <b-form-group>
              <v-select
                @input="storefilter"
                v-model="filter.selected_tag"
                label="name"
                :options="tags.list"
              />
            </b-form-group>
            <b-button block v-on:click="search(false)" variant="primary">
              <span class="align-middle">Rechercher</span>
            </b-button>
            <b-button variant="flat-primary" block v-on:click="init_filter">Reinitialiser les filtres</b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="9">
        
        <b-card v-for="call in calls.list" :key="call.index"  class="call">
          <b-row>
            <b-col cols="7" v-on:click="open_call(call.id)">
              <b-card-title class="mb-1">
                {{
                  call.titre
                }}
                
              </b-card-title>
              <div class="font-small-2">
                {{ call.nicedate }}
              </div>
              <h5 class="mb-1">
                {{ call.interlocuteur_rivalis }}  
                
                <b-badge variant="light-info" v-if="'analytics' in call && 'nb_comments' in call.analytics && call.analytics.nb_comments>0"><feather-icon icon="MessageCircleIcon" size="12" /> {{call.analytics.nb_comments}}</b-badge>
                <br />
                <span v-if="call.hubspot.contact.properties">
                  {{ call.hubspot.contact.properties.firstname }} {{ call.hubspot.contact.properties.lastname }}
                </span>
              </h5>
              <b-badge pill variant="light-dark">{{
                tagname[call.id_tag]
              }}</b-badge>
              <br />
              
            </b-col>
            <b-col cols="2">
              <!--<b-button
                  variant="relief-primary"
                  class="btn-icon float-right"
                  right
                  @click="choose_library(call)"
                >
                  <feather-icon icon="BookmarkIcon" /> Ajouter à la librairie
                </b-button>-->
              <b-button v-if="call.analyse==-1" variant="primary" size="sm" class="float-right" v-on:click="confirm_analyse(call)">Analyse de l'appel</b-button>
              <b-button v-if="call.analyse==0" variant="secondary" size="sm" class="float-right" ><feather-icon icon="ActivityIcon" size="12" /> Analyse en cours</b-button>
              <b-badge variant="light-info" v-if="call.analyse==1 && 'analytics' in call && 'tp_crpf' in call.analytics" class="float-right"><feather-icon icon="MicIcon" size="12" /> {{call.analytics.tp_crpf}}%</b-badge>
              <b-badge variant="light-info" v-if="call.analyse==1 && 'analytics' in call && 'duration' in call.analytics" class="float-right"><feather-icon icon="ClockIcon" size="12" /> {{Math.round(call.analytics.duration/60)}}mn</b-badge>
            </b-col>
            <b-col cols="3">
              <img v-if="call.canal=='meet'"
                :src="rest_end_point + '/' + call.thumbnail"
                class="float-right"
              />
              <img v-if="call.canal=='aircall'"
                src="@/assets/images/logo/aircall.png"
                class="float-right"
              />
              
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.rows"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              right
              class="float-right"
              v-on:input="paginate"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <b-modal
        ref="modal-analyse"
        id="modal-analyse"
        cancel-variant="outline-secondary"
        centered
        title="Analyse de l'appel"
        :hide-footer="true"
      >
        <b-card bg-variant="danger" text-variant="white" class="text-center">
          <b-card-text>
            <b>Une analyse coute environ 1€ (pour un appel de 45mn)</b>
          </b-card-text>
        </b-card>
        <b-card bg-variant="info" text-variant="white" class="text-center">
          <b-card-text>
            <b>Le traitement d'une analyse dure en moyenne 5mn.</b>
          </b-card-text>
        </b-card>
        <br />
        <b-button block v-on:click="launch_analyse" variant="primary">
          <span class="align-middle">Confirmer le lancement de l'analyse</span>
        </b-button>
      </b-modal>
      <b-modal
        ref="modal-select-library"
        id="modal-select-library"
        cancel-variant="outline-secondary"
        centered
        title="Ajouter à une librairie"
        :hide-footer="true"
      >
        <select-library
          v-if="folders.list.length > 0"
          :p_folders="folders.list"
          :p_id_call="$route.params.id_call"
          v-on:select_folder="select_folder"
        />
        <br />
        <b-form-textarea
          id="textarea"
          v-model="comment_library"
          placeholder="Commentaire..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <br />
        <b-button block v-on:click="add_to_library" variant="primary">
          <span class="align-middle">Ajouter à la librairie</span>
        </b-button>
      </b-modal>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BCardBody,
  BLink,
  BFormGroup,
  BButton,
  BBadge,
  BAvatar,
  BPagination,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BFormDatepicker } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import SelectLibrary from "@core/components/libraries/SelectLibrary.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    BPagination,
    BAvatar,
    VueAutosuggest,
    BCard,
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BCardBody,
    vSelect,
    BFormGroup,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BBadge,
    BSpinner,
    SelectLibrary,
    ToastificationContent
  },
  methods: {
    close_library() {
      this.$refs["modal-select-library"].hide();
    },
    confirm_analyse(call) {
      this.call_analyse = call
      this.$refs["modal-analyse"].show()
    },
    launch_analyse() {
      this.call_analyse.analyse = 0
      this.$http
          .post(
            $themeConfig.app.restEndPoint +
              "/call/" +
              this.call_analyse.id +
              "/analyse"
          )
          .then((response) => {
            this.$refs["modal-analyse"].hide()
          });
    },
    open_call(id_call) {
      this.$router.push({ name: 'call', params: { id_call: id_call } })
    },
    paginate() {
      this.storefilter()
      this.search(true)
    },
    search(paginate) {
      if (paginate == false) {
        this.pagination = {
          currentPage: null,
          perPage: 20,
          rows: 20
        }
      }
      this.$http
        .post($themeConfig.app.restEndPoint + "/calls", {
          current_page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          filter: this.filter,
          from: this.filter.from,
          to: this.filter.to,
          contact: this.filter.selected_contact,
          user: this.filter.selected_user,
          team: this.filter.selected_team,
          tag: this.filter.selected_tag,
          contact: this.filter.selected_suggest,
        })
        .then((response) => {
          this.loader = false
          
          var tags = response.data["tags"];
          for (var i = 0; i < tags.length; i++) {
            this.tagname[tags[i]["id_tag"]] = tags[i]["name"];
          }
          this.$set(this.calls, "list", response.data["calls"]);
          this.$set(this.users, "list", response.data["users"]);
          this.$set(this.tags, "list", response.data["tags"]);
          this.$set(this.teams, "list", response.data["teams"]);
          this.$set(this.pagination, "rows", response.data["rows"]);
          if (this.filter.selected_suggest)
            setTimeout(() => this.$refs.autocomplete.internalValue = this.filter.selected_suggest.fullname, 1000)
          
        });
    },
    reset_autosuggest() {
      this.filter.selected_suggest = null;
      this.$refs.autocomplete.internalValue = "";
      this.filter.query = "";
      //this.fetchResults()
    },
    fetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const contactPromise = axios.post(this.contactsUrl, { search: this.filter.query });

        Promise.all([contactPromise]).then((values) => {
          this.suggestions = [];
          this.filter.selected_suggest = null;

          const contacts = this.filterResults(
            values[0].data,
            query,
            "fullname"
          );
          contacts.length &&
            this.suggestions.push({ name: "destinations", data: contacts });
        });
      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
          return false;
        })
        .sort();
    },
    renderSuggestion(suggestion) {
      return suggestion.item.fullname;
    },
    getSuggestionValue(suggestion) {
      const { fullname, item } = suggestion;
      return item.fullname;
    },
    storefilter() {
      localStorage.setItem("filter", JSON.stringify(this.filter))
      localStorage.setItem("pagination", JSON.stringify(this.pagination))
    },
    init_filter() {
      localStorage.removeItem("filter")
      localStorage.removeItem("pagination")
      this.filter=  {
        selected_contact: {},
        selected_user: {},
        selected_team: {},
        selected_tag: {},
        selected_suggest: null,
        from: null,
        to: null,
      }
      this.search(false)
    },
    load_filter(){
      var filter = localStorage.getItem("filter")
      if (filter !== null) {
        this.filter = JSON.parse(filter)
      }
      var pagination = localStorage.getItem("pagination")
      if (pagination !== null) {
        this.pagination = JSON.parse(pagination)
      }
    },
    select_folder(folder) {
      this.selected_folder = folder;
    },
    user() {
      var user = localStorage.getItem("user");
      if (user !== null) {
        user = JSON.parse(user);
        return user["id_user"];
      } else {
        this.$router.push({ name: "login" });
      }
    },
    add_to_library() {
      if (!this.selected_folder) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aucune librairie sélectionnée",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      } else {
        this.$http
          .post(
            $themeConfig.app.restEndPoint +
              "/call/" +
              this.call_library.id +
              "/library/associate",
            {
              folder: this.selected_folder,
              comment: this.comment_library,
              id_user: this.user(),
            }
          )
          .then((response) => {
            if (response.data.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "RT ajouté à la librairie",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Déjà dans cette librairie",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.close_library();
            this.selected_folder = false;
            this.comment_library = "";
          });
      }
    },
    choose_library(call) {
      this.call_library = call
      this.$refs["modal-select-library"].show();
    },
  },
  mounted() {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    this.from = firstDay;
    this.load_filter()
    this.search(false);
    this.$http
      .post($themeConfig.app.restEndPoint + "/library/list")
      .then((response) => {
        this.$set(this.folders, "list", response.data);
      });
  },
  data() {
    return {
      call_analyse: 0,
      rest_end_point : $themeConfig.app.restEndPoint,
      loader: true,
      pagination: {
        currentPage: null,
        perPage: 20,
        rows: 20
      },
      filter: {
        selected_contact: {},
        selected_user: {},
        selected_team: {},
        selected_tag: {},
        selected_suggest: null,
        from: null,
        to: null,
      },
      calls: {
        list: [],
      },
      users: {
        list: [],
      },
      teams: {
        list: [],
      },
      tags: {
        list: [],
      },
      tagname: [],
      query: "",
      results: [],
      timeout: null,
      folders: {
        list: [],
      },
      call_library: null,
      selected_folder: false,
      comment_library: "",
      debounceMilliseconds: 250,
      contactsUrl: $themeConfig.app.restEndPoint + "/contacts",
      inputProps: {
        id: "autosuggest__input_ajax",
        placeholder: "Chercher un contact",
        class: "form-control",
        name: "ajax",
      },
      suggestions: [],
      sectionConfigs: {
        destinations: {
          limit: 20,

          onSelected: (selected) => {
            this.filter.selected_suggest = selected.item;
            this.storefilter()
            
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.call {
  cursor: pointer;
}
#wrapper_autocomplete ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
#wrapper_autocomplete li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
#wrapper_autocomplete li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest {
  width: 100%;
  display: block;
  position: relative;
}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178, 0.2);
}
#wrapper_autocomplete {
  position: relative;
}
#clear_autosuggest {
  fill: rgba(60, 60, 60, 0.5);
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  z-index: 100000;
}
</style>
